import React, { FC, useCallback, useMemo } from 'react';
import AppLink from 'components/AppLink';
import { useDataLayer } from 'hooks/useDataLayer';
import { Box, Flex, Heading, Text, Image, Icon } from '@qga/roo-ui/components';
import { Region as RegionProps } from 'types/content';
import { Grid } from './ListOfRegions.style';
import { getImage } from 'lib/sanity';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

interface Props {
  links: RegionProps[];
  title: string;
}

const StyledAppLink = styled(AppLink)`
  &:hover,
  &:active {
    color: ${themeGet('colors.greys.charcoal')};
    text-decoration-color: ${themeGet('colors.greys.charcoal')};
    text-decoration: underline;
  }
`;

const ListOfRegions: FC<Props> = ({ links = [], title }: Props) => {
  const { emitInteractionEvent } = useDataLayer();

  const handleRegionClick = useCallback(
    (fullName) => {
      emitInteractionEvent({
        type: 'Destination links',
        value: `${fullName} Selected`,
      });
    },
    [emitInteractionEvent],
  );

  const regionLinks = useMemo(
    () =>
      links.map((link) => {
        const [regionName, ...crumbs] = link.fullName.split(', ');
        const image = getImage(link?.image?.asset?.asset, { width: 340, height: 190 });
        return {
          name: `${regionName} hotels`,
          location: crumbs.join(', '),
          to: link?.url ? link.url : `/search/list?location=${link.fullName}`,
          onClick: () => handleRegionClick(link.fullName),
          image: { src: image?.custom, alt: link?.image?.name },
        };
      }),
    [links, handleRegionClick],
  );

  return (
    <Flex flexDirection="column" style={{ gap: '2rem' }}>
      <Heading.h2
        data-testid="list-regions-title"
        display="block"
        color="greys.charcoal"
        fontSize={['md', '28px', 'xl']}
        lineHeight={[undefined, 1.25]}
        fontWeight="bold"
        textAlign="center"
        mb={0}
        style={{ fontFamily: 'Jetstar' }}
      >
        {title}
      </Heading.h2>
      <Grid>
        {regionLinks?.map((link, index) => (
          <Box
            key={`${link.name}-${index}`}
            data-testid="region-link"
            bg="white"
            boxShadow="0px 1px 1px 0px rgba(0, 0, 0, 0.12)"
            borderRadius="10px"
            overflow="hidden"
          >
            <StyledAppLink to={link.to} onClick={link.onClick} target="" href={undefined} style={{ width: '100%' }}>
              <Image src={link.image.src} alt={link.image.alt} height={120} width="100%" style={{ objectFit: 'cover' }} />
              <Box p={4}>
                <Text
                  as="h3"
                  m={0}
                  fontWeight="bold"
                  color="greys.charcoal"
                  style={{
                    fontFamily: 'Jetstar',
                    // whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                  }}
                >
                  {link.name}
                  <Icon name="chevronRight" style={{ verticalAlign: 'bottom' }} />
                </Text>
              </Box>
            </StyledAppLink>
          </Box>
        ))}
      </Grid>
    </Flex>
  );
};

export default ListOfRegions;
