import React, { FC, useCallback, useMemo } from 'react';
import { Flex, Heading, Text } from '@qga/roo-ui/components';
import { LinkList } from './types';
import { useDataLayer } from 'hooks/useDataLayer';
import AppLink from 'components/AppLink';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Masonry } from 'react-masonry';

export interface PopularDestinationFooterProps {
  links: LinkList[];
}

const StyledAppLink = styled(AppLink)`
  font-size: ${themeGet('fontSizes.xs')};
  line-height: 24px;
  color: ${themeGet('colors.greys.iron')};

  &:hover,
  &:active {
    color: ${themeGet('colors.greys.steel')};
  }
`;

const PopularDestinationFooter: FC<PopularDestinationFooterProps> = ({ links }: PopularDestinationFooterProps) => {
  const { emitInteractionEvent } = useDataLayer();

  const handleDestinationClick = useCallback(
    ({ name: trackingName }) => {
      emitInteractionEvent({ type: 'Footer destination links', value: `${trackingName} Selected` });
    },
    [emitInteractionEvent],
  );

  const regionLinks = useMemo(
    () =>
      links.map((link) => ({
        title: link.title,
        regions: link.linkList.map((linkItem) => ({
          href: undefined,
          to: linkItem.to,
          name: String(linkItem.children),
          as: AppLink,
        })),
        onClick: handleDestinationClick,
      })),
    [links, handleDestinationClick],
  );

  return (
    <Flex flexDirection="column" data-testid="popular-destination-footer" bg="white" mb={[8, 8, 15]}>
      <Heading.h2
        data-testid="popular-destination-title"
        color="greys.charcoal"
        fontSize={['md', '28px', 'xl']}
        fontWeight={['bold']}
        lineHeight={[1.25]}
        mb={[6, 12]}
        textAlign="center"
        style={{ fontFamily: 'Jetstar' }}
      >
        Find accommodation in popular destinations
      </Heading.h2>
      <Masonry>
        {regionLinks.map(({ regions, title, onClick }) => (
          <Flex key={title} width={['240px', '240px', '242px']} pb="24px" flexDirection="column" style={{ gap: '.75rem' }}>
            <Text
              as="h3"
              fontSize="base"
              fontWeight="bold"
              m={0}
              style={{ fontFamily: 'Jetstar' }}
              data-testid={title ? 'region-links-header' : undefined}
              aria-hidden={!title}
            >
              {title ?? <span>&#8203;</span>}
            </Text>
            <Flex flexDirection="column">
              {regions.map((region) => (
                <StyledAppLink key={region.name} to={region.to} href={undefined} target="" onClick={() => onClick(region)}>
                  {region.name}
                </StyledAppLink>
              ))}
            </Flex>
          </Flex>
        ))}
      </Masonry>
    </Flex>
  );
};

export default PopularDestinationFooter;
